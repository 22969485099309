import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	Card,
	CardContent,
	Chip,
	Grid,
	Tab,
	Tabs,
	Typography,
	useTheme,
	Fade,
	MenuItem,
	CardActions,
	CardHeader,
	ListItemIcon
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Overview from '../Additives/Overview';
import MachinesList from './MachinesList';
import tableChipStyles from '../../styles/tableChipStyles';
import { AdditiveContext } from '../../store/AdditiveContext';
import { SAVE_SELECTED_ADDITIVE } from '../../store/AdditiveReducers';
import ProductPDF from '../ProductPDF';
import setLanguageText from '../../../language/setLanguageText';
import { UserContext } from '../../../store/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getUserLevel from '../../../helpers/getUserLevel';
import DotsMenu from '../../../reusable/DotsMenu';
import { faPencil, faTrash } from '@fortawesome/pro-light-svg-icons';
import EditProduct from '../EditProduct';
import DeleteProduct from '../DeleteProduct';

const useStyles = makeStyles(theme => ({
	tab: {
		minWidth: 'auto'
	},
	li: {
		display: 'inline'
	},
	dialog: {
		height: 'calc(100% - 64px)'
	}
}));

const CardAdditives = ({ additive, selectedType, refetch }) => {

	const { dispatchSelectedAdditive } = useContext(AdditiveContext);
	const { user } = useContext(UserContext);
	const [tabValue, setTabValue] = useState(0);
	const classes = useStyles();
	const [openEdit, setOpenEdit] = useState(false);
	const [types, setTypes] = useState();

	const openEditDialog = () => {
		setOpenEdit(true);
	}

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	useEffect(() => {
		if (selectedType?.type_id === 2 && additive) {

			//console.log('selectedType', selectedType)
			//find matching type
			let template = selectedType.type_template.find(template => template.name === 'type');
			//find matching type
			const type = template.options.find(option => option.path === additive?.product_defaults?.defaults?.type);
			setTypes(type);
		}
	}, [selectedType, additive])


	return (
		<>
			<Fade in={true} >
				<Card
					sx={{
						position: 'relative',
						height: '100%',
						display: 'flex', justifyContent: 'space-between', flexDirection: 'column', background: (theme) => theme.palette.background.default
					}}
				>
					<CardHeader
						sx={{
							background: theme => theme.palette.cards.header,
						}}
						title={
							<Grid container alignContent="center" spacing={1}>
								<Grid item>
									<Typography variant="h6">{additive.product_name}</Typography>
								</Grid>
								{types?.name ?
									<Grid item>
										<Chip
											variant='outlined'
											label={types.name}
										/>
									</Grid>
									: null}
							</Grid>
						}
						action={
							<>
								{getUserLevel(user.user, 'coolantcare', 'product_edit', 8) &&
									<DotsMenu>
										<MenuItem
											onClick={() => {
												dispatchSelectedAdditive({
													type: SAVE_SELECTED_ADDITIVE,
													payload: additive
												});
												openEditDialog();
											}} >
											<ListItemIcon>
												<FontAwesomeIcon icon={faPencil} />
											</ListItemIcon>
											Edit Product
										</MenuItem>
										<DeleteProduct product={additive} />
									</DotsMenu>
								}
							</>
						}
					/>
					<CardContent
						sx={{
							background: (theme) => theme.palette.cards.main, width: '100%',
							flexDirection: 'row-reverse',
							display: "grid",
							height: "100%",
							alignContent: "start",
							padding: 0
						}}
					>
						<Box
							sx={{
								background: theme => theme.palette.cards.footer,
							}}
						>
							<Tabs
								indicatorColor="primary"
								textColor="primary"
								value={tabValue}
								onChange={handleTabChange}
								variant="fullWidth"
							>
								<Tab label={setLanguageText("Overview")} className={`${classes.tab}`} />
								{additive?.machines_using_product?.length > 0 && (
									<Tab label={setLanguageText("Machines")} className={`${classes.tab}`} />
								)}
							</Tabs>
						</Box>
						<Box
							sx={{
								padding: '1em'
							}}
						>
							{tabValue === 0 ? (
								<>
									<Grid item container spacing={2}>
										{additive?.product_defaults?.defaults?.dilution ?
											<Grid item container spacing={2}>
												<Grid item>
													<Chip
														variant='outlined'
														color="primary"
														label={`Dilution: ${additive?.product_defaults?.defaults?.dilution}`}
													/>
												</Grid>
											</Grid>
											: null}
										<Grid item >
											<Overview
												additive={additive}
												classes={classes}
												totalMachines={additive.total_machines}
												noBar={true}
											//totalMachines={machines.length}
											/>
										</Grid>
									</Grid>
								</>
							) : (
								<MachinesList product={additive} />
							)}
						</Box>
					</CardContent>
					<CardActions sx={{ background: (theme) => theme.palette.cards.footer }}>
						<ProductPDF product={additive} />
					</CardActions>
				</Card>
			</Fade>
			{openEdit ?
				<EditProduct
					product={additive}
					types={types}
					selectedType={selectedType}
					open={openEdit}
					setOpen={setOpenEdit}
					refetch={refetch} /> : null}
		</>
	);
};

export default CardAdditives;
