import React, { useState, useContext, useEffect } from 'react';
import { Storage } from 'aws-amplify';
import { UserContext } from '../../store/UserContext';
import ProductEditForm from './form/ProductEditForm';
import { useMutation } from "@tanstack/react-query";
import config from '../../config';
import { Formik } from 'formik';
import { Button, Grid, TextField, Autocomplete } from '@mui/material';
import * as Yup from "yup"
import GlobalDialog from '../../reusable/GlobalDialog';
import setLanguageText from '../../language/setLanguageText';
import axiosRequest from '../../axios/axoisRequest';
import useActivityLog from '../../users/activity/useActivityLog';
import DialogLoader from '../../reusable/DialogLoader';

export default function EditProduct({ product, types, selectedType, open, setOpen, refetch }) {

	const { user } = useContext(UserContext);
	const [fields, setFields] = useState();
	const [loading, setLoading] = useState(false);
	const { updateActivityLog } = useActivityLog();
	//const [selectedType, setSelectedType] = useState(null);

	const saveProduct = useMutation({
		mutationFn: (body) => axiosRequest({
			gateway: config.coolantAPI.URL,
			endpoint: 'coolants',
			api_key: user.api_key,
			method: 'patch',
			body: body
		}),
		onSuccess: (response, variables) => {

			if (response.insertId) {
				//console.log(response.insertId)
				//console.log(variables)
				//loop through uploads and upload to s3
				variables.product_uploads.forEach(async (upload) => {
					//console.log(upload)
					const path = `global_coolants/${response.insertId}_${upload.language}_${upload?.name}.pdf`;
					const type = upload.type === "pdf" ? "application/pdf" : "";
					//upload to s3
					const s3Upload = await Storage.put(path, upload.file, {
						contentType: type
					});
					//console.log(s3Upload)
				})
				updateActivityLog.mutate({
					activity_details: {
						area: "coolant",
						product_id: response.insertId,
						variables
					},
					activity_type: "update product"
				})
				setLoading(false)
			}

		}
	})

	//create new product
	const editProduct = (values) => {
		//console.log(values)
		// setLoading(true)
		let productObject = {
			"product_id": values?.product_id,
			'product_name': values?.product_name,
			//'product_description': values?.product_description,
			'product_company': 0, // no compnay set at this stage
			'brand_id': values?.product_brand?.brand_id ? values?.product_brand?.brand_id : values?.brand_id,
			'product_type': values.product_type,
			"product_uploads": [],
			"product_defaults": {
				"defaults": {},
				"variants": [],
				"description": values?.product_description,
			}
		};

		console.log(productObject)

		selectedType.type_template.forEach((field) => {
			if (field.type !== "file") {
				//find matching field from values
				productObject.product_defaults.defaults[field.name] = values?.[field.name] ? values[field.name] : ''
			} else {
				//push any old file uploads
				if (values?.[field.name]) {
					productObject.product_uploads.push(values?.[field.name])
				}
			}
		})
		//console.log(values.uploads)
		//check for new uploads
		if (values.uploads) {
			Object.entries(values.uploads).forEach((value) => {
				//if there is no file 
				// console.log(value[1])
				// console.log(value[0])
				if (!value[1]) {
					//console.log('remove file')
					productObject.product_uploads = productObject.product_uploads.filter(oldUpload => oldUpload.name !== value[0])
				} else {
					//remove any old object with the same name
					productObject.product_uploads = productObject.product_uploads.filter(oldUpload => oldUpload.name !== value[1].name)
					//push new upload
					productObject.product_uploads.push(value[1])
				}
			})
		}
		//console.log(productObject)
		//upload new pdf
		productObject.product_uploads.forEach(async (upload) => {
			//check object isn't empty
			if (upload?.file && upload?.file?.name) {

				//console.log(upload)

				const path = `global_coolants/${productObject.product_id}_${upload.language}_${upload.name}.pdf`;
				const type = upload.type === "pdf" ? "application/pdf" : "";
				//upload to s3
				const s3Upload = await Storage.put(path, upload.file, {
					contentType: type
				});
				//console.log(s3Upload)
			}
		})

		//console.log(productObject)
		saveProduct.mutate(productObject)

	};

	const validation = Yup.object({
		//product_name: Yup.string().required('Product name required'),
		//product_brand: Yup.object().required('Brand required')
	});

	const closeAddDialog = () => {
		setOpen(false);
		refetch();
	}

	useEffect(() => {
		if (product) {
			//loop through fields and create form object
			let fields = {
				"product_id": product.product_id,
				'product_name': product.product_name,
				'product_description': product.product_defaults.description,
				'product_company': product.product_company,
				'brand_id': product.brand_id,
				'product_type': product.product_type,
				"uploads": []
			};

			selectedType.type_template.forEach((field) => {
				if (field) {
					//console.log(product.product_uploads)
					fields[field.name] = product.product_defaults.defaults[field.name]

					if (field.type === "file") {
						let findUpload = product.product_uploads.find(upload => upload?.name === field?.name)
						//find matching field from values
						fields[field.name] = findUpload
					}
					if (field.type === "list") {
						fields[field.name] = product.product_defaults.defaults[field.name]
					}
					if (field.type === "select") {

						//find matching field from option
						let selected = field.options.find(option => option.id === product.product_defaults.defaults[field.name])
						fields[field.name] = selected ? selected : null
					}
				}
			})
			setFields(fields)

		}
	}, [product])

	return (
		<>
			{fields ?
				<Formik
					initialValues={fields}
					validationSchema={validation}
					onSubmit={(values, { setSubmitting }) => {
						console.log(values)
						editProduct(values)
					}}
				>
					{({ submitForm, setValues }) =>

						<GlobalDialog
							open={open}
							onClose={closeAddDialog}
							title={'Edit Product'}
							buttonTitle={'Edit Product'}
							buttonClick={submitForm}
							maxWidth="md"
							fullWidth
						//disabled={true}
						>
							<Grid container spacing={2} >
								<Grid item xs={12} >
									<Autocomplete
										disabled
										fullWidth
										disableClearable
										id="tags-outlined"
										options={types || []}
										getOptionLabel={option => option.type_name || ''}
										renderInput={params => (
											<TextField
												{...params}
												variant="outlined"
												//label={setLanguageText("Select product type")}
												sx={{
													'& input': { zIndex: 2 },
													'& legend': { display: 'none' },
													'& fieldset': { backgroundColor: theme => theme.palette.mode === 'dark' ? '#000' : '#fff' },
													'& .MuiAutocomplete-endAdornment ': {
														zIndex: 2
													}
												}}
												placeholder={setLanguageText("Select Product Type")}
											/>
										)}
										onChange={
											(e, newValue) => {

												//console.log(newValue)
												let clone = structuredClone(newValue.type_template);
												clone = clone.map(field => {
													//dn't save any files fields
													if (field.type !== "file") {
														return {
															[field.name]: ''
														}
													}
												})

												setFields(newValue.type_template)
												//merge arrays
												//setSelectedType(newValue)
												clone = Object.assign({
													"product_name": "",
													"product_brand": "",
													"product_description": ""
												}, ...clone)
												setValues(clone)

											}}
										value={selectedType}
									/>
								</Grid>
								<Grid item container spacing={2} >
									{fields ?
										<ProductEditForm form={selectedType.type_template} edit={true} />
										: null}
								</Grid>
							</Grid>
							{loading || saveProduct.isLoading || saveProduct.isSuccess || saveProduct.isError ?
								<DialogLoader
									isLoading={saveProduct.isLoading}
									mutation={saveProduct}
									loadingMessage="Updating Product"
									successMessage="Product Updated"
									closeDialog={closeAddDialog}
									fixed

								/>
								: null}
						</GlobalDialog>
					}
				</Formik >
				: null
			}
		</>
	);
};
