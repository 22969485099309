import React from "react";
import { Alert } from "@mui/material";

export default function StagingSite({ fixed }) {

    const [show, setShow] = React.useState();

    React.useEffect(() => {

        console.log(window.location.href);   
        if (window.location.href.includes('toolingshop.co.uk')) {
            setShow(true);
        } else {
            setShow(false);
        }

    }, [window]);


    return (
        <>
            {show &&
                <Alert
                    variant="filled"
                    severity="error"

                    sx={{
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        zIndex: 1000,
                        borderRadius: 0,
                        padding: '1em',
                        position: fixed ? 'fixed' : 'relative',
                    }}
                >
                    THIS IS A STAGING SITE
                </Alert>
            }
        </>
    );
};