import React, { useContext, useEffect, useState } from 'react';
import { Alert, Autocomplete, Button, Card, CardContent, CardHeader, Checkbox, Collapse, Divider, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import TabHeader from '../../../layout/TabHeader';
import TabContent from '../../../layout/TabContent';
import setLanguageText from '../../../language/setLanguageText';
import { LayoutContext } from '../../../store/LayoutContext';
import { useMutation, useQuery } from 'react-query';
import axiosRequest from '../../../axios/axoisRequest';
import { CompanyContext } from '../../../store/CompanyContext';
import config from '../../../config';
import { UserContext } from '../../../store/UserContext';
import useActivityLog from '../../../users/activity/useActivityLog';
import Loader from '../../../reusable/Loader';
import { LoadingButton } from '@mui/lab';
import { SELECTED_COMPANY } from '../../../store/CompanyReducers';
import { set } from 'lodash';

const CompanyERP = () => {

	const { language } = useContext(LayoutContext);
	const { user } = useContext(UserContext);
	const { selectedCompany, dispatchSelectedCompany } = useContext(CompanyContext);
	const [selectedERP, setSelectedERP] = useState();
	const [erpUsers, setErpUsers] = useState();
	const [location, setLocation] = useState();
	const [allLocations, setAllLocations] = useState();

	const full_details = useMutation((company_id) => {
		//console.log(user)
		return axiosRequest({
			method: 'get',
			gateway: config.coolantAPI.URL,
			endpoint: 'companies',
			api_key: user.api_key,
			params: {
				id: company_id
			}
		})
	}, {
		onSuccess: (data) => {
			//update selected
			//console.log(data)
			dispatchSelectedCompany({
				type: SELECTED_COMPANY,
				payload: data[0]
			});
			//window.sessionStorage.setItem('selectedCompany', JSON.stringify(data[0]));
			window.sessionStorage.setItem('selectedCompany', data[0].company_id);
		}
	})

	const {
		updateActivityLog,
		//loggingError,
		//loggingResponse
	} = useActivityLog();

	//const storedToken = sessionStorage.getItem('token');
	const ACTstoredToken = sessionStorage.getItem('ACTtoken');
	const CISstoredToken = sessionStorage.getItem('CIStoken');

	//get erp users
	const { data, isFetching } = useQuery(['allerpusers', location?.id],
		({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.erpAPI.URL,
			endpoint: 'allusers',
			api_key: user.api_key,
			method: 'get',
			params: {
				"token": config.APPNAME === "act" ? ACTstoredToken : CISstoredToken || null,
				"location": location.id
			}
		}), {
		retry: 1,
		enabled: (!!ACTstoredToken || !!CISstoredToken) && !!location?.id,
	})

	const save_company_erp = useMutation(values => {
		//console.log(values)
		return axiosRequest({
			endpoint: 'company/settings',
			gateway: config.coolantAPI.URL,
			api_key: user.api_key,
			method: 'patch',
			body: {
				company_id: selectedCompany.company_id,
				company_location: location?.label || null,
				company_erp: values || {}
			}
		})
	}, {
		onSuccess: (data, context) => {
			updateActivityLog.mutate({
				activity_details: {
					area: "settings",
					machine_id: context.company_id,
				},
				activity_type: "company erp updated"
			})

			save_company_erp.reset();
			full_details.mutate(selectedCompany.company_id);
			setSelectedERP(null)
			setLocation(null)

		}
	})

	useEffect(() => {
		if (data) {
			setErpUsers(data)
			// if(allLocations && data.company_location){
			// 	setLocation(allLocations.find(location => location.id === data.company_location))
			// }
		}
	}, [data, allLocations])

	// useEffect(() => {
	// 	if (selectedCompany) {
	// 		//console.log(selectedCompany.company_erp);
	// 		if (selectedCompany?.company_erp) {
	// 			let erp = selectedCompany.company_erp
	// 			if (erp?.AccountNumber) {
	// 				setSelectedERP(selectedCompany?.company_erp)
	// 				setLocation(selectedCompany?.company_location ? selectedCompany.company_location : null)
	// 			} else {
	// 				setSelectedERP(null)
	// 				setLocation(null)
	// 			}
	// 		}
	// 	}
	// }, [selectedCompany]);

	const handleSubmit = (erpDetails) => {
		save_company_erp.mutate(erpDetails)
	}

	useEffect(() => {
		if (config.APPNAME === "act") {
			setAllLocations([{
				label: 'ACT',
				id: 'ACT'
			}, {
				label: 'FIFE',
				id: 'FIFE'
			}])
		}
		if (config.APPNAME === "cis") {
			setAllLocations([{
				label: 'CIS',
				id: 1
			}])
		}
	}, [])



	return (
		<>
			<TabHeader>
				<Grid item xs={12}>
					<Grid
						item
						container
						justifyContent="space-between"
						padding={1}
					>
						<Grid item xs={12} sm={'auto'}>
							<Typography variant="h5"><strong>{selectedCompany.company_name}</strong>: {setLanguageText("ERP Connection")}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</TabHeader>
			<TabContent>
				<Grid container spacing={2}>
					{selectedCompany.company_erp ?

						<Grid item xs={12}>
							<Card>
								<CardHeader
									title={"Current ERP Link"}
								//subheader={"ERP Details"}
								/>
								<CardContent>
									<Typography variant="body1">
										<strong>Location</strong>: {selectedCompany.company_erp.company_location}
									</Typography>
									<Typography variant="body1">
										<strong>Account name</strong>: {selectedCompany.company_erp.AccountName}
									</Typography>
									<Typography variant="body1">
										<strong>Account Number</strong>: {selectedCompany.company_erp.AccountNumber}
									</Typography>

									{selectedCompany.company_erp.AddressLine1 ?
										<Typography variant="body1">
											{selectedCompany.company_erp.AddressLine1}
										</Typography>
										: null}
									{selectedCompany.company_erp.AddressLine2 ?
										<Typography variant="body1">
											<strong>Address</strong>: {selectedCompany.company_erp.AddressLine2}
										</Typography>
										: null}
									{selectedCompany.company_erp.AddressLine3 ?
										<Typography variant="body1">
											{selectedCompany.company_erp.AddressLine3}
										</Typography>
										: null}
									{selectedCompany.company_erp.AddressLine4 ?
										<Typography variant="body1">
											{selectedCompany.company_erp.AddressLine4}
										</Typography>
										: null}
									{selectedCompany.company_erp.City ?
										<Typography variant="body1">
											<strong>City</strong>: {selectedCompany.company_erp.City}
										</Typography>
										: null}
									{selectedCompany.company_erp.County ?
										<Typography variant="body1">
											<strong>County</strong>: {selectedCompany.company_erp.County}
										</Typography>
										: null}
									{selectedCompany.company_erp.Country ?
										<Typography variant="body1">
											<strong>Country</strong>: {selectedCompany.company_erp.Country}
										</Typography>
										: null}
									{selectedCompany.company_erp.PostCode ?
										<Typography variant="body1">
											<strong>Postcode</strong>: {selectedCompany.company_erp.PostCode}
										</Typography>
										: null}

								</CardContent>
							</Card>
						</Grid>
						: null}
						<Divider />

					<Grid container item xs={12} spacing={2}>
						<Grid item xs={6}>
							<Autocomplete
								options={allLocations || []}
								value={location || null}
								onChange={(e, value) => {
									setLocation(value)
								}}
								isOptionEqualToValue={(option, value) => option.label === value.label}
								autoHighlight
								getOptionLabel={(option) => option.label}
								renderInput={(params, index) => (
									<TextField
										{...params}
										key={index}
										label="Link to Location"
										placeholder="Please Select"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={6}>
							<Autocomplete
								disabled={!erpUsers}
								loading={isFetching}
								options={erpUsers || []}
								value={selectedERP?.AccountName ? selectedERP : null}
								onChange={(e, value) => {
									setSelectedERP({
										...value,
										"company_location": location.label
									})

								}}
								isOptionEqualToValue={(option, value) => option.AccountNumber === value.AccountNumber}
								autoHighlight
								getOptionLabel={(option) => `${option.AccountNumber}: ${option.AccountName}`}
								renderOption={(props, option) => {
									return (
										<li {...props} key={option.AccountNumber}>
											<strong>{option.AccountNumber}:</strong> {option.AccountName}
										</li>
									);
								}}
								renderInput={(params, index) => (
									<TextField
										{...params}
										key={index}
										label="Link to ERP"
										placeholder="Please Select"
									/>
								)}
							/>

						</Grid>

						{selectedERP?.company_location && selectedERP?.AccountName ?

							<Grid item xs={12}>
								<Card>
									<CardHeader
										title={"Selected ERP Link"}
									//subheader={"ERP Details"}
									/>
									<CardContent>
										<Typography variant="body1">
											<strong>Location</strong>: {selectedERP.company_location}
										</Typography>
										<Typography variant="body1">
											<strong>Account name</strong>: {selectedERP.AccountName}
										</Typography>
										<Typography variant="body1">
											<strong>Account Number</strong>: {selectedERP.AccountNumber}
										</Typography>

										{selectedERP.AddressLine1 ?
											<Typography variant="body1">
												{selectedERP.AddressLine1}
											</Typography>
											: null}
										{selectedERP.AddressLine2 ?
											<Typography variant="body1">
												<strong>Address</strong>: {selectedERP.AddressLine2}
											</Typography>
											: null}
										{selectedERP.AddressLine3 ?
											<Typography variant="body1">
												{selectedERP.AddressLine3}
											</Typography>
											: null}
										{selectedERP.AddressLine4 ?
											<Typography variant="body1">
												{selectedERP.AddressLine4}
											</Typography>
											: null}
										{selectedERP.City ?
											<Typography variant="body1">
												<strong>City</strong>: {selectedERP.City}
											</Typography>
											: null}
										{selectedERP.County ?
											<Typography variant="body1">
												<strong>County</strong>: {selectedERP.County}
											</Typography>
											: null}
										{selectedERP.Country ?
											<Typography variant="body1">
												<strong>Country</strong>: {selectedERP.Country}
											</Typography>
											: null}
										{selectedERP.PostCode ?
											<Typography variant="body1">
												<strong>Postcode</strong>: {selectedERP.PostCode}
											</Typography>
											: null}

									</CardContent>
								</Card>
							</Grid>
							: null}

					</Grid>

					<Grid item xs={12} >
						<LoadingButton
							disabled={save_company_erp.isLoading || save_company_erp.isLoading || full_details.isLoading}
							loading={save_company_erp.isLoading || full_details.isLoading}
							variant='contained'
							color='primary'
							onClick={() => handleSubmit(selectedERP)}
						>
							Update erp link
						</LoadingButton>
					</Grid>
				</Grid>
			</TabContent>
			{save_company_erp.isLoading || full_details.isLoading || save_company_erp.isSuccess || save_company_erp.isError ?
				<Loader
					isLoading={save_company_erp.isLoading || full_details.isLoading}
					mutation={save_company_erp}
					loadingMessage="Saving ERP Link..."
					successMessage="Saved ERP Link"
				/>
				: null}
		</>
	);
};

export default CompanyERP;
