import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axiosRequest from "../axios/axoisRequest";
import { UserContext } from "../store/UserContext";
import config from "../config";
import SearchSelect from "./SearchSelect";
import { isMobile } from "react-device-detect";
import queryGetFilters from "../opensearch/queryGetFilters";

export default function SearchFilters({ field, filters, defaultManufacturers, searchParams, setSearchParams }) {

    //console.log(filters)
    const [filter, setFilter] = useState([]);
    const { user } = useContext(UserContext);

    const getFilters = useQuery({
        queryKey: ['filters', field, defaultManufacturers, filters?.type, filters?.q],
        queryFn: () => {

            const searchQuery = queryGetFilters({     
                "term": filters.q,
                "field": field,
                "type":filters.type,
                "filters": {
                    "manufacturer": defaultManufacturers
                },
                "sort": filters?.sort ? filters.sort : [] 

                //"sort": searchSort
            })
           
            return axiosRequest({
                name: `${field} filters`, //added to see which call is erroring
                gateway: config.opensearchAPI.URL,
                endpoint: 'tools-search/filters',
                api_key: user.api_key,
                method: 'get',
                method: 'post',
                body: {
                    "query" : searchQuery,
                    "index": config.opensearchIndex
                }
            })
        },
        keepPreviousData: true,
        enabled: !!field && !!filters?.type && !!defaultManufacturers, //only run if searchValue and searchType are present
        //placeholderData: keepPreviousData, // don't have 0 rows flash while changing pages/loading next page
    })

    useEffect(() => {
        if (getFilters?.data?.aggregations?.genres?.buckets) {
            //make alphabetically sorted array of objects
            //console.log(getFilters.data.aggregations.genres.buckets)
            let sorted = getFilters.data.aggregations.genres.buckets.sort((a, b) => a.key.localeCompare(b.key))
            setFilter(sorted)
        }
    }, [getFilters])

    const updateFilters = (updatedFilter) => {
        //loop updatedFilter and get array of the keys
        let array = []
        updatedFilter.forEach(item => array.push(item.key))
        //add filter to url
        const newUrl = searchParams
        //console.log(JSON.stringify(array))  
        if (array.length === 0) {
            newUrl.delete(field)
            newUrl.set("page", 0);
        } else {
            newUrl.set(field, encodeURI(array));
            newUrl.set("page", 0);
        }
        setSearchParams(newUrl)
    }

    return (
        <Grid item container spacing={isMobile? 1 :2}>
            {/* {filter &&
                <SearchCheckboxes
                    filter={filter}
                    updateFilters={updateFilters}
                    title={field}
                    isFetching={getFilters.isFetching}
                />
                } */}
            {filter &&
                <SearchSelect
                    searchParams={searchParams}
                    filter={filter}
                    updateFilters={updateFilters}
                    title={field}
                    isFetching={getFilters.isFetching}
                />
            }
        </Grid>
    );
};

