import { faDolly, faTruck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React from "react";
import config from "../../config";

export default function Delivery({ product, table, large, isFetching }) {

    return (
        <>
            {/* {!product?.error ? */}
                <>
                    {large ?
                        <Alert
                            icon={false}
                            sx={{
                                background: "#e9f6fb",
                                alignItems: 'center',
                                marginTop: table ? '5px' : '0',
                                borderRadius: 0,
                                'li': {
                                    padding: '5px 0'
                                },
                                '& svg': {
                                    fontSize: '20px',
                                    '& path': {
                                        fill: theme => theme.palette.info.main
                                    }
                                },
                                '& .MuiListItemIcon-root': {
                                    minWidth: '40px'
                                }
                            }}
                            severity="info"
                            variant="outlined"
                        >
                            <List dense={true}
                                sx={{
                                    color: (theme) => theme.palette.text.primary,
                                    padding: '0'

                                }}
                            >
                                {product?.product_stock > 0 ?
                                    <ListItem >
                                        <ListItemIcon>
                                            <FontAwesomeIcon icon={faDolly} />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <>
                                                <strong>{config.APPNAME === "act" ? "ACT" : config.APPNAME === "cis" ? "CIS" : "ERP"} Stock:  </strong> {product.product_stock} available
                                                {product?.product_local_delivery ?
                                                    <>
                                                        <br />
                                                        <strong>Estimated Delivery:</strong> {product.product_local_delivery}
                                                    </>
                                                    : null}
                                            </>
                                        </ListItemText>
                                    </ListItem>
                                    : null}
                                <ListItem>
                                    <ListItemIcon>
                                        <FontAwesomeIcon icon={faTruck} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <>
                                            <strong>Manufacturer Stock:</strong> Available
                                            {product?.product_external_delivery ?
                                                <>
                                                    <br />
                                                    <strong>Estimated Delivery:</strong> {product.product_external_delivery}
                                                </>
                                                : null}
                                        </>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Alert>

                        : product?.product_local_delivery || product?.product_external_delivery ?

                            <Alert
                                icon={
                                    <FontAwesomeIcon icon={product?.product_local_delivery && product?.product_stock > 0 ? faDolly : faTruck} style={{ marginTop: '2px' }} size={large ? "lg" : "sm"} />
                                }
                                sx={{
                                    background: '#e9f6fb',
                                    padding: '0 8px',
                                    alignItems: 'center',
                                    borderRadius: 0,
                                    height: '100%',
                                    minHeight: table ? '50px' : 'auto',
                                }}
                                severity="info"
                                variant="outlined"
                            >
                                {product?.product_local_delivery && product?.product_stock > 0 ?
                                    <Typography
                                        sx={{
                                            fontSize: large ? '20px' : table ? '13px' : '14px',
                                        }}
                                        variant="p"
                                        component="span"
                                    >
                                        <strong>Est. Delivery:</strong> {product.product_local_delivery}
                                    </Typography>
                                    : product?.product_external_delivery ?
                                        <Typography
                                            sx={{
                                                fontSize: large ? '20px' : table ? '13px' : '14px',
                                            }}
                                            variant="p"
                                            component="span"
                                        >
                                            <strong>Est. Delivery:</strong> {product.product_external_delivery}
                                        </Typography>
                                        : null}
                            </Alert>
                            : null}
                </>
                {/* : null} */}
        </>
    )
}