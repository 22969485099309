import React, { useContext, useEffect, useState } from 'react';
import {
	Alert,
	Box,
	Divider,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import config from '../../config';
import { useFormikContext } from 'formik';
import FileUpload from '../../media/FileUpload';
import InputWrap from '../../reusable/InputWrap';

export default function ShopForm({ setFileUpload, file, preview }) {

	const { values, touched, errors, setErrors, setValues, handleChange, setFieldValue } = useFormikContext()

	//console.log(file)

	return (

		<Grid container spacing={2}>
			<Grid container spacing={2} item xs={12} md={6}>
				<Grid item xs={12}>
					<TextField
						required
						id="brand_name" r
						name="brand_name"
						label="ERP Manufacturer / Brand Name"
						value={values?.brand_name}
						onChange={handleChange}
						fullWidth
						variant="outlined"
						error={touched.brand_name && Boolean(errors.brand_name)}
						helperText={touched.brand_name && errors.brand_name}
					/>
					<Alert
						sx={{ marginTop: '8px' }}
						severity="info">This is the manufacturer name that will link these details to the products.</Alert>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="local_delivery"
						name="local_delivery"
						label="Local Stock Delivery Time"
						value={values?.local_delivery}
						onChange={handleChange}
						fullWidth
						variant="outlined"
						error={touched.local_delivery && Boolean(errors.local_delivery)}
						helperText={touched.local_delivery && errors.local_delivery}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="external_delivery"
						name="external_delivery"
						label="External Stock Delivery Time"
						value={values?.external_delivery}
						onChange={handleChange}
						fullWidth
						variant="outlined"
						error={touched.external_delivery && Boolean(errors.external_delivery)}
						helperText={touched.external_delivery && errors.external_delivery}
					/>
				</Grid>

				<Grid item xs={12}>
					<TextField
						id="erp_code"
						name="erp_code"
						label="ERP Supplier Code"
						value={values?.erp_code}
						onChange={handleChange}
						fullWidth
						variant="outlined"
						error={touched.erp_code && Boolean(errors.erp_code)}
						helperText={touched.erp_code && errors.erp_code}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="supplier_name"
						name="supplier_name"
						label="ERP Supplier Name"
						value={values?.supplier_name}
						onChange={handleChange}
						fullWidth
						variant="outlined"
						error={touched.supplier_name && Boolean(errors.supplier_name)}
						helperText={touched.supplier_name && errors.supplier_name}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="supplier_code"
						name="supplier_code"
						label="CIM Code"
						value={values?.supplier_code}
						onChange={handleChange}
						fullWidth
						variant="outlined"
						error={touched.supplier_code && Boolean(errors.supplier_code)}
						helperText={touched.supplier_code && errors.supplier_code}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12} md={6} >
				<InputWrap name="Manufacturer / brand logo">
					<Grid container item spacing={2}>


						{file ?
							<Grid item xs={12}>
								<Box
									sx={{
										width: '100%',
										height: '160px',
										backgroundPosition: '0px 0px, 10px 10px',
										backgroundSize: '20px 20px',
										backgroundImage: 'linear-gradient(45deg, #bbb 25%, transparent 25%, transparent 75%, #bbb 75%, #bbb 100%),linear-gradient(45deg, #bbb 25%, #ccc 25%, #ccc 75%, #bbb 75%, #bbb 100%)'
									}}
								>
									<img src={URL.createObjectURL(file)} alt="logo" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
								</Box>
							</Grid>

							: values.brand_logo ?
								<Grid item xs={12}>
									<Box
										sx={{
											width: '100%',
											height: '160px',
											backgroundPosition: '0px 0px, 10px 10px',
											backgroundSize: '20px 20px',
											backgroundImage: 'linear-gradient(45deg, #bbb 25%, transparent 25%, transparent 75%, #bbb 75%, #bbb 100%),linear-gradient(45deg, #bbb 25%, #ccc 25%, #ccc 75%, #bbb 75%, #bbb 100%)'
										}}
									>
										<img src={values.brand_logo} alt="logo" style={{ width: '100%', height: '100%', objectFit: "contain" }} />
									</Box>
								</Grid>
								: null}


						<Grid item xs={12} >
							<FileUpload
								handleSelectUploadedFile={(file) => {
									setFileUpload(file)
									//set the brand url
									setFieldValue('brand_logo', `https://${config.brandLogos.BUCKET}.s3.${config.brandLogos.REGION}.amazonaws.com/public/${values.brand_name}`)
								}}
								padding="24px"
								title="Drop logo here or"
								//isSingle
								dropzoneProps={{
									noClick: true,
									accept: {
										'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
									},
									//maxFiles: 1,
									//maxSize: 5242880,
									//multiple: true,
								}}
							/>

						</Grid>
					</Grid>
				</InputWrap>
			</Grid>
		</Grid >
	);
};
