import { useContext, useEffect, useState } from 'react';
import config from '../config';
import axiosRequest from '../axios/axoisRequest';
import { UserContext } from '../store/UserContext';

import { useQuery } from '@tanstack/react-query';

export default function usePricePrefefetch() {

    const { user } = useContext(UserContext)    
    const CISstoredToken = sessionStorage.getItem('CIStoken');

    const { data } = useQuery({
        queryKey: ['prefetch'],
        queryFn: ({ signal }) => {
            return (
                axiosRequest({
                    signal: signal,
                    gateway: config.opensearchAPI.URL,
                    endpoint: 'tools-search/price',
                    api_key: user.api_key,
                    method: 'get',
                    params: {
                        "supplier": "CIS",
                        "account": "TPC001",
                        "token": CISstoredToken || null,
                        "id": "000000",
                        "index" : config.opensearchIndex
                    }
                })
            )
        },
        enabled: !!user.api_key && !!CISstoredToken && config.APPNAME === "cis",
        retry: 1
    })
    //console.log(data)

};

