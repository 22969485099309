import React, { useState, Fragment, useContext } from 'react';
import {
	Card,
	CardContent,
	Tab,
	Tabs,
	Typography,
	ListItemIcon,
	Fade,
	MenuItem,
	CardActions,
	CardHeader,
	Box
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Overview from '../Coolants/Overview';
import Defaults from '../Coolants/Defaults';
import MachinesList from './MachinesList';
import { faPencil, faTrash } from '@fortawesome/pro-light-svg-icons';
import { SAVE_SELECTED_COOLANT } from '../../store/CoolantReducers';
import { CoolantContext } from '../../store/CoolantContext';
import setLanguageText from '../../../language/setLanguageText';
import { UserContext } from '../../../store/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DotsMenu from '../../../reusable/DotsMenu';
import getUserLevel from '../../../helpers/getUserLevel';
import ProductPDF from '../ProductPDF';
import EditProduct from '../EditProduct';
import DeleteProduct from '../DeleteProduct';

const useStyles = makeStyles(theme => ({
	tab: {
		minWidth: 'auto'
	},
	li: {
		display: 'inline'
	},
	cardFooter: {
		padding: theme.spacing(1),
		borderTop: `1px solid ${theme.palette.background.light}`,
		background: `${theme.palette.background.light}`
	},
	dialog: {
		height: 'calc(100% - 64px)'
	},
	leftButton: {
		paddingLeft: '4px'
	},
	rightButton: {
		paddingRight: '4px'
	}
}));

const CoolantCard = ({
	coolant,
	selectedType,
	noMachines,
	types,
	refetch
}) => {

	const { dispatchSelectedCoolant } = useContext(CoolantContext);
	const { user } = useContext(UserContext);
	const [tabValue, setTabValue] = useState(0);
	const [openEdit, setOpenEdit] = useState(false);
	const classes = useStyles();

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	const openEditDialog = () => {
		setOpenEdit(true);
	}

	return (
		<>
			<Fade in={true}>
				<Card
					sx={{
						position: 'relative',
						height: '100%',
						display: 'flex', justifyContent: 'space-between', flexDirection: 'column', background: (theme) => theme.palette.background.default
					}}
				>
					<CardHeader
						sx={{
							background: theme => theme.palette.cards.header,
						}}
						title={<Typography variant="h6">{coolant.product_name}</Typography>}
						action={
							<>
								{getUserLevel(user.user, 'coolantcare', 'product_edit', 8) &&
									<DotsMenu>
										<MenuItem
											onClick={() => {
												dispatchSelectedCoolant({
													type: SAVE_SELECTED_COOLANT,
													payload: coolant
												});
												openEditDialog()
											}} >
											<ListItemIcon>
												<FontAwesomeIcon icon={faPencil} />
											</ListItemIcon>
											Edit Product
										</MenuItem>
										<DeleteProduct product={coolant} />
									</DotsMenu>
								}
							</>
						}
					/>
					<CardContent
						sx={{
							background: (theme) => theme.palette.cards.main, width: '100%',
							flexDirection: 'row-reverse',
							display: "grid",
							height: "100%",
							alignContent: "start",
							padding: 0
						}}
					>
						<Box
							sx={{
								background: theme => theme.palette.cards.footer,
							}}
						>
							<Tabs
								indicatorColor="primary"
								textColor="primary"
								value={tabValue}
								onChange={handleTabChange}
								variant="fullWidth"
								sx={{ padding: 0 }}
							>
								<Tab label={setLanguageText("Overview")} className={`${classes.tab}`} />
								<Tab label={setLanguageText("Defaults")} className={`${classes.tab}`} />
								{coolant?.machines_using_product?.length > 0 && (
									<Tab label={setLanguageText("Machines")} className={`${classes.tab}`} />
								)}
							</Tabs>
						</Box>
						<Box
							sx={{
								padding: '1em'
							}}
						>
							{tabValue === 0 ? (
								<Overview
									coolant={coolant}
									classes={classes}
									totalMachines={coolant.total_machines}
								/>
							) : tabValue === 1 ? (
								<Defaults coolant={coolant} noHeader />
							) : (
								<>
									{!noMachines ? <MachinesList product={coolant} /> : null}
								</>
							)}
						</Box>
					</CardContent>
					<CardActions sx={{ background: (theme) => theme.palette.cards.footer }}>
						{coolant?.product_defaults ?
							<ProductPDF product={coolant} />
							: null
						}
					</CardActions>
				</Card>
			</Fade>

			{openEdit ?
				<EditProduct
					product={coolant}
					types={types}
					selectedType={selectedType}
					open={openEdit}
					setOpen={setOpenEdit}
					refetch={refetch}
				/> : null}

		</>
	);
};

export default CoolantCard;
