import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
	Alert,
	Box,
	Button,
	Grid,
	MenuItem,
	Typography
} from '@mui/material';
import { UserContext } from '../../store/UserContext';
import { BrandsContext } from '../../store/BrandsContext';
import GlobalDialog from '../../reusable/GlobalDialog';
import setLanguageText from '../../language/setLanguageText';
import useActivityLog from '../../users/activity/useActivityLog';
import TabContent from '../../layout/TabContent';
import TabHeader from '../../layout/TabHeader';
import ShopTable from './ShopTable';
import DotsMenu from '../../reusable/DotsMenu';
import ShopForm from './ShopForm';
import { Formik } from 'formik';
import config from '../../config';
import DialogLoader from '../../reusable/DialogLoader';
import axiosRequest from '../../axios/axoisRequest';
import { useMutation, useQuery } from '@tanstack/react-query';
import { UPDATE_BRANDS } from '../../store/BrandsReducers';
import { Storage } from 'aws-amplify';

export default function ShopBrands() {


	Storage.configure({
		AWSS3: {
			bucket: config.brandLogos.BUCKET,
			region: config.brandLogos.REGION
		}
	});


	const { user } = useContext(UserContext);
	const { dispatchBrands } = useContext(BrandsContext);
	const [editOpen, setEditOpen] = useState(false);
	const [selected, setSelected] = useState();
	const [brands, setBrands] = useState();
	const [newBrand, setNewBrand] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [file, setFile] = useState()

	const { data, isFetching, refetch } = useQuery({
		queryKey: ['opensearch brands'],
		queryFn: ({ signal }) => axiosRequest({
			signal: signal,
			gateway: config.opensearchAPI.URL,
			endpoint: 'tools-search/brands',
			api_key: user.api_key,
			method: 'get'
		}),
		retry: 1
	})

	useEffect(() => {
		if (data) {
			//console.log(brands)
			if (!data?.errorMessage) {
				//console.log(brands)
				dispatchBrands({
					type: UPDATE_BRANDS,
					payload: {
						openSearchBrands: data
					}
				})
				setBrands(data)
			}
		}
	}, [data])


	const { updateActivityLog } = useActivityLog();

	const columns = useMemo(() => [
		{
			accessorKey: 'id',
			header: () => 'id',
			cell: info => info.getValue(),
			size: 40,
			enableSorting: false,
			hidden: true,
			//minSize: 400,
			//maxSize: 400
		}, {
			header: 'ERP Manufacturer / Brand Details',
			footer: props => props.column.id,
			columns: [
				// {
				// 	name: 'Name',
				// 	accessorKey: 'dataset',
				// 	header: () => 'Name',
				// 	cell: info => info.getValue(),
				// 	size: 140,

				// }, {
				{
					name: 'Brand Name',
					accessorKey: 'brand_name',
					header: () => 'Brand Name',
					cell: info => info.getValue(),
					size: 240,
				}
				, {
					name: 'ERP Supplier Name',
					accessorKey: 'supplier_name',
					header: () => 'ERP Supplier Name',
					cell: info => info.getValue(),
					size: 200,
					//enableColumnFilter: false,
				},{
					name: 'ERP Supppier Code',
					accessorKey: 'erp_code',
					header: () => 'ERP Supplier Code',
					cell: info => info.getValue(),
					size: 200,
					//enableColumnFilter: false,
				},{
					name: 'Supplier Code',
					accessorKey: 'supplier_code',
					header: () => 'CIM Code',
					cell: info => info.getValue(),
					size: 200,
					enableColumnFilter: false,
				}
			]
		}, {
			header: 'Shop Settings',
			footer: props => props.column.id,
			columns: [
				{
					accessorKey: 'brand_logo',
					header: () => 'Brand Logo URL',
					enableColumnFilter: false,
					cell: info => {
						let url = info.getValue();
						return (
							<Box
								sx={{
									width: '100px',
									height: '80px'
								}}
							>
								{url ? <img src={url} alt="logo" style={{ width: '100%', height: '100%', objectFit: "contain" }} /> : "No Logo"}
							</Box>
						)
					},
					size: 240,

				}, {
					accessorKey: 'local_delivery',
					header: () => 'Local Stock Delivery',
					cell: info => info.getValue(),
					enableColumnFilter: false,
					size: 200,
				}, {
					accessorKey: 'external_delivery',
					header: () => 'External Stock Delivery',
					cell: info => info.getValue(),
					enableColumnFilter: false,
					size: 200,
				}]
		}, {
			header: () => 'Actions',
			id: 'actions',
			enableColumnFilter: false,
			//accessorKey: 'order',
			cell: info => {
				//console.log(info)
				return (
					<Grid container spacing={1}>
						<DotsMenu>
							<MenuItem onClick={(e) => openEditDialog(e, info.row)}>Edit</MenuItem>
							<MenuItem onClick={(e) => openDeleteDialog(e, info.row)}>Delete</MenuItem>

							{/* <MenuItem onClick={(e) => openDeleteDialog(e, info.row)}>Delete</MenuItem> */}
						</DotsMenu>
					</Grid>
				)
			},
			size: 100
		}
	], [])


	const updateBrand = useMutation({
		mutationKey: ['edit brand'],
		mutationFn: (values) => {
			return axiosRequest({
				gateway: config.opensearchAPI.URL,
				endpoint: 'tools-search/brands',
				api_key: user.api_key,
				method: 'post',
				body: {
					...values,

					"add_brand": newBrand
				}
			})
		},
		onSuccess: async (response, variables) => {

			if (!response.errorMessage) {
				// const file_ext = getFileExtension(file[0])
				// const file_path = `resources/${response.insertId}.${file_ext}`;
				try {
					//console.log(variables)
					// add thmbnail if preview image is set
					if (file) {
						try {
							await Storage.put(`${variables.brand_name}`, file, {
								progressCallback(progress) {
									console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
								},
								contentType: file.type,
								acl: 'public-read' // This sets the uploaded file as publicly readable
							});
						} catch (error) {
							console.log("Upload Error: ", error);
						}
					}
				} catch (error) {
					console.log(error);
				} finally {
					// const string = `resource ${variables.delete ? 'deleted' : 'updated'}`
					// updateActivityLog.mutate({
					// 	activity_details: {
					// 		area: "factory",
					// 	},
					// 	activity_type: string
					// })
					//console.log(response)
					setFile()
					setSelected()
					setEditOpen(false)
					refetch()
					
				}
			}
		}
	})

	const deleteBrand = useMutation({
		mutationKey: ['delete brand'],
		mutationFn: (values) => {
			return axiosRequest({
				gateway: config.opensearchAPI.URL,
				endpoint: 'tools-search/brands',
				api_key: user.api_key,
				method: 'post',
				body: {
					...values,
					"remove_brand": true
				}
			})
		},
		onSuccess: (response) => {
			//console.log(response)
			setConfirmDelete(false)
			refetch()
			setSelected()
		}
	})

	const openEditDialog = (e, row) => {
		setFile()
		setSelected(row.original);
		setEditOpen(true);
	};

	const openDeleteDialog = (e, row) => {
		setFile()
		setSelected(row.original);
		setConfirmDelete(true);
	};

	const setFileUpload = (file) => {
		setFile(file)
	}

	return (
		<>
			<TabHeader>

				<Grid
					container
					justifyContent="space-between"
				>
					<Grid item xs={12} sm={'auto'}>
						<Typography variant="h5">{setLanguageText("Shop Brands")}</Typography>
					</Grid>
					<Grid item >
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								setFile()
								setSelected({
									"dataset": "",
									"supplier_code": "",
									"supplier_name": "",
									"brand_name": "",
									"brand_logo": "",
									"erp_code": "",
									"local_delivery": "",
									"external_delivery": ""
								})
								setEditOpen(true)
								setNewBrand(true)
							}}
						>
							Add Brand
						</Button>
					</Grid>
				</Grid>
			</TabHeader>
			<TabContent>
				<ShopTable
					data={brands}
					columns={columns}
					isFetching={isFetching}
				/>
			</TabContent>

			{selected && editOpen ?
				<Formik
					initialValues={selected}
					//validationSchema={validation}
					onSubmit={(values, { setSubmitting }) => {
						//console.log(values)
						updateBrand.mutate(values)
					}}
				>
					{({ submitForm, setValues }) =>

						<GlobalDialog
							open={editOpen}
							onClose={() => setEditOpen(false)}
							title={newBrand ? "New Brand" : "Edit Brand"}
							buttonClick={submitForm}
							buttonTitle={newBrand ? "Save Brand" : "Update Brand"}
							successMessage={newBrand ? "New Brand addded!" : "Brand editted successfully!"}
							fullWidth
							maxWidth="md"
						>
							<ShopForm
								setFileUpload={setFileUpload}
								file={file}
							/>
							{updateBrand.isLoading || updateBrand.isSuccess || updateBrand.isError ?
								<DialogLoader
									isLoading={updateBrand.isLoading}
									mutation={updateBrand}
									loadingMessage="Saving Brand"
									successMessage="Saved Brand"
									//closeDialog={closeRefreshDialog}
									fixed
								/>
								: null}
						</GlobalDialog>
					}
				</Formik>
				: null}

			{confirmDelete &&
				<GlobalDialog
					open={confirmDelete}
					onClose={() => setConfirmDelete(false)}
					title="Delete Brands"
					buttonTitle="Delete Brand"
					buttonClick={() => {
						deleteBrand.mutate(selected)
					}}
					maxWidth="md"
					fullWidth
				>
					<Grid item>
						<Alert severity="error">
							Are you sure you want to delete this brand?
						</Alert>
					</Grid>
				</GlobalDialog>
			}
		</>
	);
};

