import React, { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { Alert, Grid, useMediaQuery, Typography, Button, Box } from "@mui/material"

const FileUpload = ({ dropzoneProps, handleSelectUploadedFile, isSingle, disabled , title, padding}) => {

	const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		acceptedFiles,
		open,
		fileRejections
	} = useDropzone(dropzoneProps)

	useEffect(() => {
		//console.log(acceptedFiles)
		if (acceptedFiles.length) {
			acceptedFiles.forEach((file) => {
				//console.log(acceptedFiles)
				handleSelectUploadedFile(file)
			})
		}
	}, [acceptedFiles])

	return (
		<>
			{isMd && !isSingle ?
				<Box
					sx={{
						borderStyle: 'dashed',
						//borderWidth: 'thick',
						padding: padding ? padding :'8em',
						borderSpacing: 'large',
						//margin: '1em ',
						borderColor: (theme) =>
							isDragActive ? theme.palette.success.main
								: fileRejections.length ? theme.palette.error.main
									: theme.palette.text.disabled
					}}
					{...getRootProps()}
				>
					<Grid container alignItems="center" alignContent="center" spacing={2} >
						<input {...getInputProps()} />
						<Grid item xs={12}>
							<Typography component="p" variant="h6" align="center">
								{title ? title : "Drop files here or"}
							</Typography >
						</Grid>
						<Grid item xs={12} sx={{ textAlign: 'center' }}>
							<Button
								variant="outlined"
								color="primary"
								onClick={open}
								disabled={disabled}

							>
								Browse
							</Button>
							{/* <Typography component="p" variant="p" sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={open}>
						Select files from your computer
					</Typography> */}
						</Grid>
					</Grid>
				</Box>
				:
				<Grid item xs={12} >
					<input {...getInputProps()} />
					<Button
						variant="contained"
						color="primary"
						onClick={open}
						disabled={disabled}
					>
						{isSingle ? 'Upload file from your device' : 'Upload files from your device'}
					</Button>
				</Grid>
			}
			{fileRejections.length ? (
				<Grid item xs={12} sx={{ marginTop: '1em' }}>
					<Alert severity="error" >
						{fileRejections[0].errors[0].code === "file-too-large"
							? "You selected an image larger than 5.2 MB, please select a smaller size image"
							: fileRejections[0].errors[0].message}
					</Alert>
				</Grid>
			) : null}
		</>
	)
}

export default FileUpload
